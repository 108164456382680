<template>
<div>
<Loading v-if="isLoading" />
  <div v-if="!isLoading" class="complete-account-container">
    <div class="complete-account">
      <h2 class="complete-account__title">Complete your account setup</h2>
    <div class="complete-account__row">
      <selectAvatar ref='accountAvatar' @imageSelected="imageSelected"/>
      <accountSetup ref='accountSetupForm' @enableButton="enableButton"/>
    </div>
    </div>
    <div class="footer">
      <div class="footer__terms">
        <input type="checkbox" id="terms" v-model="terms" />
        <label for="terms">
          I undestand and agree to the Brains Terms of Service, including
          the User Agreement and Privacy Policy.
        </label>
      </div>
      <button
        id="next"
        class="footer__button"
        :disabled="(!isEnable || !terms || !isImageSelected)"
        @click="createProfile()">
        Create Profile
      </button>
    </div>
  </div>
  </div>
</template>

<script>
import accountSetup from '@/views/CompleteAccountSetupForm/Components/AccountSetup.vue';
import SelectAvatar from './Components/SelectAvatar.vue';
import Loading from "../../components/Loading.vue";

export default {
  name: 'CompleteAccountSetupForm',
  components: {
    accountSetup,
    SelectAvatar,
    Loading,
  },

  data() {
    return {
      data: null,
      step: 0,
      terms: false,
      isEnable: false,
      isImageSelected: false,
      submitData: {},
      isLoading: false,
    };
  },

  async mounted() {
    const isValid = await this.validateToken(this.$route.query.token);
    if (!isValid) {
      this.$toast.add({
        severity: 'error',
        summary: 'Unauthorised',
        detail: 'Invalid or expired token',
        life: 4000,
      });
    }
  },

  methods: {
    async validateToken(token) {
      localStorage.setItem('brains-register-token', token);
      const response = await this.$store.dispatch('freelancer/validateToken');
      let isValid = true;
      if (response) {
        isValid = false;
      }
      return isValid;
    },

    async savePhoto(file) {
      const response = await this.$store.dispatch('freelancer/savePhoto', file);
      if (response.status !== 200) {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Error saving the photo',
          life: 4000,
        });
      } else {
        this.submitData.photo = response.data;
      }
    },

    async createProfile() {
      this.isLoading = true;
      this.submitData = this.$refs.accountSetupForm.submit();
      const file = await this.$refs.accountAvatar.getFile();
      await this.savePhoto(file);
      localStorage.setItem('brains-user-registrated', JSON.stringify(this.submitData));
      const response = await this.$store.dispatch(
        'freelancer/registerUser', this.submitData,
      );
      if (response.status !== 200) {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Error creating user',
          life: 4000,
        });
      } else {
        localStorage.setItem('brains-register-token', response.data.access_token);
        this.$router.push({ path: '/contact-data/' });
      }
      this.isLoading = false;
    },

    enableButton(validForm) {
      this.isEnable = validForm;
    },
    imageSelected(isSelected) {
      this.isImageSelected = isSelected;
    },
    verifyStep(val) {
      if (val.data === '+1') {
        this.step += 1;
      }
      if (val.data === '-1') {
        this.step -= 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.complete-account-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.complete-account {
  width: 100%;
  &__title {
    font-size: 2rem;
    text-align: center;
    margin-top: 0;
  }
  &__row {
    display: flex;
    margin-top: 70px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  &__terms {
    display: flex;
    justify-content: center;
  }
  &__button {
    @include primary-button();
    margin-top: 3rem;
  }
}
</style>
