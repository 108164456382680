<template>
  <div class="photo-container">
    <div class="photo">
      <img v-if="imageUrl" :src="imageUrl" alt="User icon" class="photo__icon" />
      <img v-else src="@/assets/images/userIcon.png" alt="User icon" class="photo__icon" />
    </div>
    <input type="file" id="file" accept="image/*" @change="handleAvatarChange"/>
    <div class="input-photo-container">
      <label class="small-secondary-button" for="file" >
          Select
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectAvatar',
  data() {
    return {
      file: null,
      imageUrl: '',
      formData: null,
    };
  },
  methods: {
    getUrl() {
      return this.imageUrl;
    },
    getFile() {
      const formData = new FormData();
      formData.append('photo', this.file, this.file.name);
      return formData;
    },
    handleAvatarChange(event) {
      [this.file] = event.target.files;
      if (this.file) {
        const formData = new FormData();
        formData.append('photo', this.file, this.file.name);
        this.formData = formData;
        this.imageUrl = URL.createObjectURL(this.file);
        this.$emit('imageSelected', true);
      } else {
        this.imageUrl = '';
        this.formData = null;
        this.$emit('imageSelected', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.photo {
  border-radius: 50%;
  height: 170px;
  width: 170px;
  border: 3px solid darkgray;
  background-color: white;
  overflow: hidden;
  &__icon {
    height: 100%;
    width: 100%;
  }
}
input {
  display: none;
}
.small-secondary-button {
  @include secondary-button();
  font-size: 0.7rem;
  min-height: 1.5rem;
  min-width: 5rem;
  background-color: white;
  max-height: 25px;
}
.photo-container {
  // width: 10%;
}

.input-photo-container {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
}
</style>
