¿<template>
  <main class="account-setup">
      <form class="form" @submit.prevent="submitForm">
        <div class="row form__row">
          <div class="column row__column">
            <label for="firstName" class="column__input-label">First name *</label>
            <input autocomplete="off" placeholder="Enter name"
              class="column__input"
              type="text"
              id="firstName"
              v-model.trim="firstName"
              required
            />
            <div class="error-message">
              <span class="form__error" v-if="msg.firstName">{{msg.firstName}}</span>
            </div>
          </div>
          <div class="column row__column">
            <label class="column__input-label" for="secondName">Second name</label>
            <input autocomplete="off" placeholder="Enter name"
              class="column__input"
              type="text"
              id="secondName"
              v-model.trim="secondName"
            />
            <div class="error-message">
              <span class="form__error" v-if="msg.secondName">{{msg.secondName}}</span>
            </div>
          </div>
        </div>
        <div class="row form__row">
          <div class="column row__column">
            <label for="lastName" class="column__input-label">Last name *</label>
            <input autocomplete="off" placeholder="Enter last name"
              class="column__input"
              type="text"
              id="lastName"
              v-model.trim="lastName"
              required
            />
            <div class="error-message">
              <span class="form__error" v-if="msg.lastName">{{msg.lastName}}</span>
            </div>
          </div>
          <div class="column row__column">
            <label class="column__input-label" for="citizenship"
              >Citizenship *</label>
            <Dropdown class="column__select" v-model="selectedCitizenship"
              :options="countriesList"
              placeholder="Select a country"/>
          </div>
        </div>
        <div class="row form__row">
          <div class="column row__column">
            <label class="column__input-label" for="password">Password *</label>
            <input autocomplete="off" placeholder="Enter password"
              class="column__input"
              type="password"
              id="password"
              v-model="password"
              required
            />
            <div class="error-message__pdw">
              <span class="form__error" v-if="msg.password">{{msg.password}}</span>
            </div>
          </div>
          <div class="column row__column">
            <label class="column__input-label" for="confirmPassword"
              >Confirm Password *</label>
            <input autocomplete="off" placeholder="Repeat the password"
              class="column__input"
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              required
            />
            <div class="error-message__pdw">
              <span class="form__error" v-if="msg.confirmPassword">{{msg.confirmPassword}}</span>
            </div>
          </div>
        </div>
      </form>
  </main>
</template>

<script>

export default {
  name: 'CompleteAccountSetupForm',
  data() {
    return {

      firstName: '',
      secondName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      msg: [],
      selectedCitizenship: null,
      countriesList: ['Colombia', 'Argentina', 'USA', 'Mexico'],
      formCorrect: false,
      submitForm: '',
    };
  },
  watch: {
    firstName(value) {
      // binding this to the data value in the email input
      const exp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
      this.validateName(value, 'firstName', exp);
    },
    secondName(value) {
      const exp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]*$/;
      this.validateName(value, 'secondName', exp);
    },
    lastName(value) {
      const exp = /^[a-zA-Z\s*À-ÿ\u00f1\u00d1]+$/;
      this.validateName(value, 'lastName', exp);
    },
    password(value) {
      this.validatePassword(value);
    },
    confirmPassword(value) {
      this.verifyPassword(value);
    },
  },
  methods: {
    submit() {
      return {
        first_name: this.firstName,
        second_name: this.secondName,
        last_name: this.lastName,
        citizenship: this.selectedCitizenship,
        photo: '',
        password: this.password,
        confirm_password: this.confirmPassword,
      };
    },
    validateName(value, inputId, exp) {
      if (exp.test(value)) {
        this.msg[inputId] = '';
      } else {
        this.msg[inputId] = 'Invalid name';
      }
      this.checkForm();
    },
    validatePassword(value) {
      if (/^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(value)) {
        this.msg.password = '';
      } else {
        this.msg.password = ('Password must contain at least: '
        + 'one capital letter, one number and one special character');
      }
      if (this.password.length < 8) {
        this.msg.password = 'Password must be at least 8 characters';
      }
      this.checkForm();
    },
    verifyPassword(value) {
      if (value !== this.password) {
        this.msg.confirmPassword = 'Passwords do not match';
      } else {
        this.msg.confirmPassword = '';
      }
      this.checkForm();
    },
    checkForm() {
      if (!(Object.values(this.msg).some((error) => error.length))
      && this.firstName !== '' && this.lastName !== '' && this.confirmPassword !== ''
      && this.selectedCitizenship !== null) {
        this.formCorrect = true;
      } else {
        this.formCorrect = false;
      }
      this.$emit('enableButton', this.formCorrect);
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  &__error {
    color: $alert-color;
    font-size: 12px;
    margin: 0 0 0 0.5rem;
  }
  &__row {
    display: flex;
    padding-left: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.row {
  &__column {
    width: 48%;
  }
}
.column {
  &__input {
    @include input(100%);
  }
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__select {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 17px;
  }
  &__error {
    width: 280px;
  }
}
.error-message {
  overflow: hidden;
  width: 100%;
  height: 17px;
  &__pdw {
    width: 100%;
    height: 60px;
  }
}
.account-setup {
  width: 50%;
}
</style>
